.navbar {
  font-size: 1.5em;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.invisible {
  visibility: hidden;
}

.home {
  color: #9b9a9a;
  font-family: 'Pinyon Script', cursive;
  font-size: calc(18px + 2vmin);
}

.home:active {
  color: #9b9a9a;
}

.home[tabindex]:focus {
  color: #9b9a9a;
  outline: none;
}
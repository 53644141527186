* {
    box-sizing: border-box;
  }
.images-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Arapey', Arial, Helvetica, sans-serif;
    font-size: calc(18px + 1vmin);
    width: 100%;
    height: 100vh;
}

.content {
    width: 70%;
    padding-bottom: 5vh;
}


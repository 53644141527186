.info {
    display:flex;
    flex-direction: column;
    padding: 2%;
}

.preface {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Arapey';
    font-size: calc(12px + 1vmin);
    width: 100%
}

.content {
    width: 70%;
}

.header {
    font-size: calc(18px + 1vmin);
    padding: 10px;

}

.toast {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Arapey';
    width: 100vw;
}

.toastheader {
    font-size: calc(14px + 1vmin);
}

.toastmasters {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(12px + 1vmin);

}
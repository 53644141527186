.burger-menu-base-layer {
    width: 100%;
    height: 100%;
}

.burger-menu-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.menu-button{
    width: 6vh;
    height: auto;
    z-index: 10;

}

.menu-button button {
    height: 100%;
    width: 100%;
    border: 0;
    background-color: white;

}

.menu-button button:focus {
    outline:none;
}

.menu-button svg{
    width: 100%;
    height: 100%;
    fill: #9b9a9a;
}

.button-state-bars .svg-burger-top{
    transform-origin: top left;
    transform: rotate(0deg);
    transition: transform 0.2s;
}

.button-state-bars .svg-burger-bottom{
    transform-origin: bottom left;
    transform: rotate(0deg);
    transition: transform 0.2s;
}

.button-state-times .svg-burger-top{
    transform-origin: top left;
    transform: translateY(-15px) translateX(33px) rotate(45deg);
    transition: transform 0.2s;
}

.button-state-times .svg-burger-bottom{
    transform-origin: bottom left;
    transform: translateY(10px) translateX(27px) rotate(-45deg);
    transition: transform 0.2s;
}

.menu-item-list {
    background-color: white;
    height:100vh;
    z-index: 6;
    position: absolute;
    right:0px;
    bottom:0px;
    left:0px;
    /*overscroll-behavior: contain;*/
    overflow: hidden;
}

.menu-item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@keyframes disappear {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
    
}

.upload-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.progress-bar {
    padding:1em
}

input[type="submit"] {
    width: 33%;
    height: auto;
    font-size: calc(18px + 1vmin);
    border: 1 solid black;
    padding: 2%;
    margin: 2%;
    border-radius: 4px;
}
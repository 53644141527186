.menu-title {
    font-family: 'Arapey', serif;
    display: block;
    animation: slideIn 1s, appear 2s;
    margin: 0 2vw;
    opacity: 1;
    padding: 1vh;
    color: #9b9a9a;
}

@keyframes slideIn {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.active .menu-title{
    opacity: 1;
    text-decoration: underline;
}

a {
    text-decoration: none;
}
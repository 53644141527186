.section {    
    text-align: center;
    display: flex;
    flex-direction: row ;
    justify-content: stretch;
    align-items: stretch;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 1%;
    /*border: 1px solid black;*/
}

.mobile-section {    
  text-align: center;
  display: flex;
  flex-direction: column ;
  justify-content: center;
  align-items: center;
  padding: 2%;

}
  
.img {    
  width: 40vw;
  max-height: 20vw;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  background-color: aqua;
}

.mobile-img {    
  min-width: 100vw;
  min-height: 33vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.render {
  min-width: 100%;
  min-height: 100%;
  /*width:100%;
  height:100%;*/
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: calc(2px + 2vmin); ;
  font-family:  'Arapey';
  font-size: calc(12px + 1vmin);;
  background-color: white;
  width: 80%;
}

.title {
  font-family: 'Pinyon Script';
  font-size: calc(24px + 2vmin);;
}
.direction {
  padding: 5%;
  z-index: 100;
}
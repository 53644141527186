
.custom-button:focus {
        outline: none;
    }

.custom-button {
    font-family: 'Arapey', Arial, Helvetica, sans-serif;
    width: auto;
    height: auto;
    font-size: calc(18px + 1vmin);
    border: 1 solid black;
    padding: 2%;
    margin: 2%;
    border-radius: 4px;
    color: black;
}
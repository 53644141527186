.App {
  text-align: center;
  color: #9b9a9a;
  font-family: 'Arapey', serif;
  /*background-color: white;*/
}

.App-logo {
  height: auto;
  max-width: 1vw;
  pointer-events: none;
}

.App-header {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(8px + 2vmin);
  overflow: hidden;
  width: auto;
  padding-top: 5vh;
}



.greeting {
  font-family: 'Arapey', serif;
  font-size: calc(18px + 1vmin);
  
}

.info-text {
  padding-bottom: 0;
  font-size: calc(12px + 1vmin);
}

.App-link {
  color: #61dafb;
}

.splash-image {
  overflow: hidden;
  width: auto;
  /*padding: 5vh;*/
}


.menu-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #9b9a9a;
    font-family: 'Pinyon Script', cursive;
    font-size: calc(18px + 2vmin);
    width: 100%;
}

.header{
    font-size: calc(28px + 2vmin);
    font-family: 'Pinyon Script', cursive;
    padding-bottom: 5vh;
}

.entry {
    font-family: 'Arapey';
    padding:5vw;
}

.menu-entry-header {
    font-size: calc(22px + 2vmin);
    font-family: 'Pinyon Script', cursive;
}

.menu-entry-main {
    font-size: calc(12px + 2vmin);
    padding: 1vh;
}

.menu-entry-description {
    font-size: calc(8px + 2vmin);
}

.menu-entry-allergies {
    font-size: calc(4px + 2vmin);
}

.allergy-explanation {
    font-family: 'Arapey';
    font-size: calc(6px + 2vmin);
    padding-bottom: 10vh;
}
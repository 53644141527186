.toast-section {    
    text-align: center;
    display: flex;
    flex-direction: column ;
    justify-content: stretch;
    align-items: stretch;
    padding: 5%;
    width: 100%;
    /*border: 1px solid black;*/
}

.name {
    font-size: calc(14px + 1vmin);
    color: #686868;


}

.info {
    display: flex;
    flex-direction: column ;
    justify-content: stretch;
    align-items: stretch;
}
@import url('https://fonts.googleapis.com/css2?family=Arapey&familyPinyon+Script&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Arapey', 'Pinyon Script',  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #9b9a9a;
  /*background-color: white;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  height: 100vh;
}